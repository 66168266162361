import React from 'react'

const HorariosContinuosPageConfig =  {
   settings: {
       layout: {
           config: {}
       }
   },
   routes: [
       {
           path: '/horarioscontinuos',
           component: React.lazy(()=>import('./HorariosContinuosPage'))
       }
   ]
};

export default HorariosContinuosPageConfig
