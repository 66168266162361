import Axios from 'axios';
import { permiso } from '../jwtService/helper';


export const crearHorariosAtencion = async (horaInicial,horaFinal,turno,codigoOficina,nombreOficina,jlOficinaId, municipioId,atencion, idDepartamento) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const cuerpo={
            horaInicial,
            horaFinal,
            turno,
            codigoOficina,
            nombreOficina,
            jlOficinaId,
            municipioId,
            atencion,
            idDepartamento,
        }
        const respuesta = await Axios.post(`${rutabase}/api/v1/horarioatencion`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}

export const listartHorariosAtencion = async (skip, take,oficina, codigo,turno, atencion, departamentoId) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    let cuerpo;
    const permi = permiso('FP_VER_NACIONAL');
    let depa;
    if (!permi) {
        depa = Number(localStorage.getItem('jwt_access_tema'));
    }else{
        depa = departamentoId;
    }


    if (turno===0 && atencion === 0 && depa===0) {
        cuerpo ={
            skip,
            take,
            bsqOficina:oficina,
            bsqCodigo:codigo,
        }
    }else{
        if (turno!==0 && atencion ===0 && depa===0) {
            cuerpo ={
                skip,
                take,
                bsqOficina:oficina,
                bsqCodigo:codigo,
                bsqTurno:turno,
            }
        }else{
            if (turno===0 && atencion !==0 && depa===0) {
                cuerpo ={
                    skip,
                    take,
                    bsqOficina:oficina,
                    bsqCodigo:codigo,
                    bsqAtencion:atencion,
                }
            }else{
                if (turno===0 && atencion ===0 && depa!==0) {
                    cuerpo ={
                        skip,
                        take,
                        bsqOficina:oficina,
                        bsqCodigo:codigo,
                        bsqDepartamentoId:depa
                    }
                }
                else{
                    if (turno!==0 && atencion !==0 && depa===0) {
                        cuerpo ={
                            skip,
                            take,
                            bsqOficina:oficina,
                            bsqCodigo:codigo,
                            bsqTurno:turno,
                            bsqAtencion:atencion,
                        }
                    }else{
                        if (turno!==0 && atencion ===0 && depa!==0) {
                            cuerpo ={
                                skip,
                                take,
                                bsqOficina:oficina,
                                bsqCodigo:codigo,
                                bsqTurno:turno,
                                bsqDepartamentoId:depa
                            }
                        }else{
                            if (turno===0 && atencion !==0 && depa!==0) {
                                cuerpo ={
                                    skip,
                                    take,
                                    bsqOficina:oficina,
                                    bsqCodigo:codigo,
                                    bsqAtencion:atencion,
                                    bsqDepartamentoId:depa
                                }
                            }else{
                                if (turno!==0 && atencion !==0 && depa!==0) {
                                    cuerpo ={
                                        skip,
                                        take,
                                        bsqOficina:oficina,
                                        bsqCodigo:codigo,
                                        bsqTurno:turno,
                                        bsqAtencion:atencion,
                                        bsqDepartamentoId:depa
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    try {
        const respuesta = await Axios.post(`${rutabase}/api/v1/horarioatencion/listar`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: err,
        }
    }
}

export const eliminarHorariosAtencion = async (id,estado)=>{
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.delete(`${rutabase}/api/v1/horarioatencion/${id}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                            data:{estado:estado}
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });

        const {error, message, response, status} = respuesta;

        return {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}

export const actualizarHorariosAtencion = async (id,horaInicial,horaFinal,turno,codigoOficina,nombreOficina,jlOficinaId, municipioId, atencion, idDepartamento) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const cuerpo={
            horaInicial,
            horaFinal,
            turno,
            codigoOficina,
            nombreOficina,
            jlOficinaId,
            municipioId,
            atencion,
            idDepartamento,
        }
        const respuesta = await Axios.put(`${rutabase}/api/v1/horarioatencion/${id}`, cuerpo, 
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}
