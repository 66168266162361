import { IconButton, Menu, MenuItem, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { purple } from '@material-ui/core/colors';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import Interruptor from 'app/main/components/table/Interruptor';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
	root: {
		'&.user': {
			'& .username, & .email': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	avatar: {
		width: 72,
		height: 72,
		position: 'absolute',
		top: 92,
		padding: 8,
		background: theme.palette.background.default,
		boxSizing: 'content-box',
		left: '50%',
		transform: 'translateX(-50%)',
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		'& > img': {
			borderRadius: '50%'
		}
	}
}));

function UserNavbarHeader(props) {
	const user = useSelector(({ auth }) => auth.user);
	console.log(user)
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);


	const handleClick = (event) => setAnchorEl(event.currentTarget);



	const handleClose = () => setAnchorEl(null);
	const hadleCloseLogout = () =>{
		localStorage.removeItem('jwt_access_token');
		localStorage.removeItem('jwt_access_config_tema');
		localStorage.removeItem('jwt_access_tema');
		delete axios.defaults.headers.common.Authorization;
	};

	return (
		<AppBar
			position="static"
			color="primary"
			classes={{ root: classes.root }}
			className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-32 z-0 shadow-0"
		>
			<Typography className="username text-16 font-600 whitespace-nowrap" color="inherit">
				{user.data.displayName}
				<IconButton
					className="pl-12"
					aria-label="configuracion"
					aria-controls="simple-menu"
					aria-haspopup="true"
					size="small"
					onClick={handleClick} >
					<SettingsIcon style={{ color: purple[400] }} titleAccess="CONFIGURACION"/>
				</IconButton>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem>
						<Interruptor estad={user.data.themeBool} id={user.data.idUserTriton} tipo={4} />
					</MenuItem>
					<MenuItem onClick={hadleCloseLogout} >
						<IconButton aria-label="SALIR" href={`${process.env.REACT_APP_URL_PAGE}/login`} >
							<ExitToAppIcon  titleAccess="SALIR"/>
						</IconButton>
					</MenuItem>
				</Menu>
			</Typography>
			<Typography className="email text-13 mt-8 whitespace-nowrap" color="inherit">
				{user.data.email}
			</Typography>
			<Avatar
				className={clsx(classes.avatar, 'avatar')}
				alt="user photo"
				src={
					user.data.photoURL && user.data.photoURL !== ''
						? user.data.photoURL
						: 'assets/images/avatars/profile.jpg'
				}
			/>
		</AppBar>
	);
}

export default UserNavbarHeader;
