import React from 'react'

const TiposHorarioPageConfig =  {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/tiposHorario',
            component: React.lazy(()=>import('./TiposHorarioPage'))
        }
    ]
}

export default TiposHorarioPageConfig
