import Axios from 'axios';
import { permiso } from '../../../app/services/jwtService/helper';

export const registrarFeriado = async (dep, muni, fecha, detalle) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        let municipi;
        if (muni===null) {
            municipi= null;
        } else {
            municipi=muni.id;
        }
        let departa;
        if (dep===null) {
            departa= null;
        } else {
            departa=dep.id;
        }
        const cuerpo={
            departamento: departa,
            municipio   : municipi,
            fecha       : fecha,
            detalle     : detalle,
        }
        const respuesta = await Axios.post(`${rutabase}/api/v1/feriado`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            }
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}

export const listartFeriados = async (skip, take, fecha, mes, depId) => {

    const rutabase = process.env.REACT_APP_CONEXION;

    const permi = permiso('FP_VER_NACIONAL');
    let depa;
    if (!permi) {
        depa = Number(localStorage.getItem('jwt_access_tema'));
    }else{
        depa = depId;
    }

    const cuerpo = {
        skip,
        take,
        bsqFecha:fecha,
        bsqMes:mes,
        bsqDepartamento: depa,
    }
    try {
        const respuesta = await Axios.post(`${rutabase}/api/v1/feriado/listar`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`,
                                                'departamento' : depa
                                            }
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });

        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: err,
        }
    }
}

export const eliminarFeriado = async (id, estado) =>{

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.delete(`${rutabase}/api/v1/feriado/${id}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                            data:{estado:estado}
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });

        const {error, message, response, status} = respuesta;
        return {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}

export const actualizarFeriado = async (id, dep, muni, fecha, detalle) => {
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        let municipi;
        if (muni===null) {
            municipi= null;
        } else {
            municipi=muni.id;
        }
        let departa;
        if (dep===null) {
            departa= null;
        } else {
            departa=dep.id;
        }
        const cuerpo={
            departamento: departa,
            municipio   : municipi,
            fecha       : fecha,
            detalle     : detalle,
        }
            const respuesta = await Axios.put(`${rutabase}/api/v1/feriado/${id}`, cuerpo, 
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}