import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
//import ExampleConfig from 'app/main/example/ExampleConfig';
import pagesConfigs from 'app/main/pages/pagesConfigs';
import Error404PageConfig from 'app/main/pages/error/404/Error404PageConfig';
import LoginConfig from 'app/main/login/LoginConfig';
import GestorPersonasConfig from 'app/main/pages/getorPersonas/GestorPersonasConfig';
import VerMarcacionPageConfig from 'app/main/pages/verMarcacones/VerMarcacionPageConfig';
import ListMarcacionesPageConfig from 'app/main/pages/listMarcaciones/ListMarcacionesPageConfig';

const routeConfigs = [
	...pagesConfigs,
	LoginConfig,
	Error404PageConfig,
	VerMarcacionPageConfig,
	ListMarcacionesPageConfig,
	GestorPersonasConfig,
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin']),
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/gestorPersonas" />
	},
	{
		component: () => <Redirect to="/error-404" />
	},
];

export default routes;
