import { authRoles } from 'app/auth';
import React from 'react'

const ListMarcacionesPageConfig =  {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: true
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/listaasistencias',
			component: React.lazy(() => import('./ListMarcaciones'))
		}
	]
};

export default ListMarcacionesPageConfig