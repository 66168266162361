import { authRoles } from 'app/auth'
import React from 'react'

const GestorPersonasConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.admin,
    routes: [
        {
            path: '/gestorPersonas',
            component: React.lazy(()=>import('./GestorPersonas'))
        }
    ]
}

export default GestorPersonasConfig

