//import DispositivosPageConfig from "./dispositivos/DispositivosPageConfig";
//import Error404PageConfig from "./error/404/Error404PageConfig";
//import AdministradorHorariosPageConfig from "./administradorHorarios/AdministradorHorariosPageConfig";
import DashboartConfig from "./dashboart/DashboartConfig";
import FeriadosPageConfig from "./feriados/FeriadosPageConfig";
import GestorPersonasConfig from "./getorPersonas/GestorPersonasConfig";
import HorariosContinuosPageConfig from "./horariosContinuos/HorariosContinuosPageConfig";
import OficinasPageConfig from "./oficinas/OficinasPageConfig";
import PersonasPageConfig from "./personas/PersonasPageConfig";
import PersonasPruebasPageConfig from "./personasPruebas/PersonasPruebasPageConfig";
import TiposHorarioPageConfig from "./tiposHorario/TiposHorarioPageConfig";

const pagesConfigs =[ HorariosContinuosPageConfig, FeriadosPageConfig, OficinasPageConfig, /* AdministradorHorariosPageConfig, */ PersonasPageConfig, GestorPersonasConfig, TiposHorarioPageConfig, PersonasPruebasPageConfig, DashboartConfig, ];

export default pagesConfigs;