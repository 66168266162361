import React from 'react'

const PersonasPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/personasDescontinuado',
            component: React.lazy(()=>import('./PersonasPage'))
        }
    ]
}

export default PersonasPageConfig
