import Axios from 'axios';

export const buscarCasoTriton = async (caso) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.get(`${rutabase}/api/v1/triton/caso/${caso}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: `NO SE LOGRO ENCONTRAR EL CASO: ${caso}`,
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}

export const buscarCasoDatos = async (caso) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.get(`${rutabase}/api/v1/triton/caso/solo/${caso}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: `NO SE LOGRO ENCONTRAR EL CASO: ${caso}`,
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}

export const buscarCasoEros = async (caso) => {
    const rutabase = process.env.REACT_APP_SERVICE_LOGIN;
    const cuerpo = {
        cud:caso
    }
    try {
        const respuesta = await Axios.post(`${rutabase}plataforma/v2/getcud`,cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: `NO SE LOGRO ENCONTRAR EL CASO: ${caso}`,
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}
//TODO - revision
export const buscarPersonaPleyades = async (idPersona) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    const data = {'conFoto':true,'conCertificado':false}
    try {
        const respuesta = await Axios.get(`${rutabase}/api/v1/triton/sujeto/procesal/${idPersona}`,
            data,
            {
                headers:{
                    'Content-Type': 'application/json',
                }
            }).then((resp)=>{ return resp.data})
                .catch((error)=>{
                    return error.response.data
                });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };
    } catch (error) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}

export const buscarOficina = async (codigoDep) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.get(`${rutabase}/api/v1/triton/oficinas/${Number(codigoDep)}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });

        const {error,response, message, status} = respuesta;

        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: error,
        }
    }
}

export const buscarOficinaId = async(idOficina)=>{
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.get(`${rutabase}/api/v1/triton/oficinasid/${idOficina}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });

        const {error,response, message, status} = respuesta;

        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: error,
        }
    }
}

export const listarActividades = async (idCaso) => {
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        console.log(`${rutabase}/api/v1/triton/actividades/${idCaso}`)
        const respuesta = await Axios.get(`${rutabase}/api/v1/triton/actividades/${idCaso}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: `NO SE LOGRO ENCONTRAR EL CASO: ${idCaso}`,
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}

export const actualizarUserConfig = async(id, themeNombre,themeId)=>{
    const rutabase = process.env.REACT_APP_CONEXION;

    const cuerpo={
        themeNombre: themeNombre,
        themeId: themeId,
    }
    try {
        const respuesta = await Axios.put(`${rutabase}/api/v1/userconfig/${id}`,cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: `NO SE LOGRO ENCONTRAR ACTUALIZAR EL USERCONFIG`,
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}

export const buscarCronoGramaPersona = async (ci) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.get(`${rutabase}/api/v1/getmaracion/${ci}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error,response, message, status} = respuesta;
        return {
            mensaje: message,
            estado : status,
            respuesta: response,
            errores: error,
        };

    } catch (error) {
        return {
            mensaje: `NO SE LOGRO ENCONTRAR EL CI: ${ci}`,
            estado : 500,
            respuesta: error,
            errores: true,
        }
    }
}