import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {encode} from './helper';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (numeroDocumento, password) => {
		const rutabase = process.env.REACT_APP_SERVICE_LOGIN;
		const codigoAplicacion= process.env.REACT_APP_APLICACION_CODIGO;
		const rutaback = process.env.REACT_APP_CONEXION;
		return new Promise((resolve, reject) => {
			axios
				.post(`${rutabase}/api/v1/auth/login`,
				{
					aplicacion: codigoAplicacion,
					usuario: numeroDocumento,
					password,
					verificarSiFuncionario: true,
				},
				{headers:{'Content-Type': 'application/json', }}
				)
				.then(response => {
					//==== VERIDICA SI EXISTE ERROR EN LOGINJL ====
					if (!response.data.error) {
						this.setSession(response.data.response.token);
						this.setPermisos(response.data.response.permisos, response.data.response.funcionario.departamentoId );
						let resp ={...response.data.response.funcionario,
							...{
								fotoRuta:response.data.response.usuario.fotoRuta,
								nombre:response.data.response.usuario.nombre,
								ci:response.data.response.usuario.ci,
							}};
						const cuerpo = {
							personaId: response.data.response.funcionario.funcionarioId
						}
						//==== GUARDA AL NUEVO USUARIO EN BD FIRMAR SI EXISTE NO HACE NADA  ====
						axios.post(`${rutaback}/api/v1/userconfig`, cuerpo, {headers:{'Content-Type': 'application/json', 'Authorization':`Bearer ${this.getAccessToken()}`}})
						.then(response =>{
							if (!response.data.error) {
								resolve(resp);
							} else {
								resolve(resp);
							}
						})
						.catch(err =>{
							const errorperso= {email:'verifique su ci', password:'verifique su contraseña'};reject(errorperso)
						})
						
					} else {
						//DEVULEVE UN REJECT SI ERROR TRUE EN LOGIN JL ====
						const errorperso= {email:'no cuenta con usuario i4', password:'verifique su contraseña'};
						reject(errorperso);
					}
				}).catch(err =>{const errorperso= {email:'verifique su ci', password:'verifique su contraseña'};reject(errorperso)});
		});
	};

	signInWithToken = () => {
		const rutabase = process.env.REACT_APP_SERVICE_LOGIN;

		//=== VERIFICA SI EL TOKEN ES VALIDO AUN ===
		return new Promise((resolve, reject) => {
			axios
				.post(`${rutabase}/api/v1/perfil-persona/validate`, {},{
					headers:{'Content-Type': 'application/json', 'Authorization':`Bearer ${this.getAccessToken()}`,}
				})
				.then(response => {
					if (!response.data.error) {
						axios.get(`${rutabase}/api/v1/perfil-persona/information`, {headers:{'Content-Type': 'application/json', 'Authorization':`Bearer ${this.getAccessToken()}`}})
						.then(response =>{
							if (!response.data.error) {
								response.data.response.funcionario = {
									...response.data.response.funcionario,
									...{
										fotoRuta:response.data.response.usuario.fotoRuta,
										nombre:response.data.response.usuario.nombre,
										ci:response.data.response.usuario.ci,
									}}
								resolve(response.data.response.funcionario);
							} else {
								resolve(response.data.response.funcionario);
							}
						})
						.catch(err =>{
							reject(new Error('Fallo al logearse con JL.'));
						})
						this.setSession(this.getAccessToken());
						
					} else {
						this.logout();
						reject(new Error('Fallo al logearse con JL.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Tiempo de sesión finalizada.'));
				});
		});
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	setPermisos = (permisos, depa) => {
		if (permisos.length !== 0) {
			const nose = encode(JSON.stringify(permisos));
			//=== SE CARGAN LOS DATOS DEL LOS PERMISOS EN EL STORAGE ====
			localStorage.setItem('jwt_access_config_tema', nose);
			localStorage.setItem('jwt_access_tema', depa);
		} else {
			localStorage.removeItem('jwt_access_config_tema');
			localStorage.removeItem('jwt_access_tema');
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			//console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};
}

const instance = new JwtService();

export default instance;
