import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Backdrop, CircularProgress, makeStyles, Snackbar, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
//import { useSelector } from 'react-redux';
import { eliminarFeriado } from 'app/services/apiFirmasPresentaciones/feriadosServices';
import { eliminarHoraContinuo } from 'app/services/apiFirmasPresentaciones/HoraContinuoServices';
import { eliminarHorariosAtencion } from 'app/services/apiFirmasPresentaciones/horariosAtencionServices';
import { eliminarTipoHorario } from 'app/services/apiFirmasPresentaciones/tipoHorarioService';
import { actualizarUserConfig } from 'app/services/apiTriton/apiTriton';


const   useStyles = makeStyles((theme)=>({
            backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        snackbar:{
            '& > *': {
              margin: theme.spacing(5),
            },
        },
        }));

const Interruptor = props => {
    const {estad, id, tipo} = props;

    ///const user = useSelector(({ auth }) => auth.user);

    const clases = useStyles();

    const [estado, setEstado] = useState(estad);
    const [backDropState, setBacDropState] = useState(false);
    const [openSms, setOpenSms] = useState(false);
    const [tipoAlert, setTipoAlert] = useState('info');
    const [mensajeRespuesta, setMensajeRespuesta] = useState('')

    const handleClose = () =>setBacDropState(false);
    const handleCloseSms =()=>setOpenSms(false);

    const handleChangeEstado = async() =>{
        let registro;
        let estd;
        if (estado) {
            estd=0;
        } else {
            estd=1;
        }
        setBacDropState(!backDropState);

        switch (tipo) {
            case 0:
                registro = await eliminarFeriado(id, estd); //{errores : true, mensaje:'todo bien en el swich Feriados'};//await Feriados///llamr al servicio delete;
            break;
            case 1:
                registro = await eliminarHoraContinuo(id, estd); //{errores : true, mensaje:'todo bien en el swich HoraContinuo'};//await HoraContinuo///llamr al servicio delete;
            break;
            case 2:
                registro = await eliminarHorariosAtencion(id, estd); //{errores : true, mensaje:'todo bien en el swich HoraContinuo'};//await HoraContinuo///llamr al servicio delete;
            break;
            case 3:
                registro = await eliminarTipoHorario(id, estd); //{errores : true, mensaje:'todo bien en el swich HoraContinuo'};//await HoraContinuo///llamr al servicio delete;
            break;
            case 4:
                    let themeNombre;
                    let themeId;
                    if (estad) {
                        themeNombre='light';
                        themeId=0;
                    } else {
                        themeNombre='dark';
                        themeId=1;
                    }
                registro = await actualizarUserConfig(id, themeNombre,themeId); //{errores : true, mensaje:'todo bien en el swich HoraContinuo'};//await HoraContinuo///llamr al servicio delete;
            break;
            default:
                break;
        }

        /* if (tipo>=1) {
            registro = await eliminarHoraContinuo(id, estd); //{errores : true, mensaje:'todo bien en el swich HoraContinuo'};//await HoraContinuo///llamr al servicio delete;
        } else {
            registro = await eliminarFeriado(id, estd); //{errores : true, mensaje:'todo bien en el swich Feriados'};//await Feriados///llamr al servicio delete;
        } */
            if (!registro.errores) {
                setTipoAlert('success');
                setEstado(!estado);
            }else{
                setTipoAlert('error');
            }
            setBacDropState(false);
            setMensajeRespuesta( registro.mensaje );
            setOpenSms(true);
            handleClose();
    }

    return (
        <div>
            <Switch
                checked={estado}
                onChange={handleChangeEstado}
                name="estado"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Backdrop className={clases.backdrop} open={backDropState} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
            className={clases.snackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSms}
                autoHideDuration={3000}
                onClose={handleCloseSms}
            >
                <Alert onClose={handleCloseSms} severity={tipoAlert}>
                    {mensajeRespuesta}
                </Alert>
            </Snackbar>
        </div>
    )
}

Interruptor.propTypes = {
estad: PropTypes.bool.isRequired,
id: PropTypes.number.isRequired,
tipo: PropTypes.number,
}

export default Interruptor
