import React from 'react'


const DashboartConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/analytics',
            component: React.lazy(()=>import('./Dashboart'))
        }
    ]
}

export default DashboartConfig
