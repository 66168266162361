import base64 from 'base-64';
import utf8 from 'utf8';

export const encode = (var1) => {

    try {
        const bytes = utf8.encode(var1);
        return base64.encode(bytes);
    } catch (error) {
        return null
    }
}

export const decode = (var1) =>{
    try {
        const bytes = base64.decode(var1);
        return utf8.decode(bytes);
    } catch (error) {
        return null
    }
}

export const permiso = (permiso) => {
    let permi;
    if (localStorage.getItem('jwt_access_config_tema') !== null) {
        const bytes = base64.decode(localStorage.getItem('jwt_access_config_tema'));
        permi = JSON.parse(utf8.decode(bytes));
    } else {
        permi = null;
    }
    //const permi = JSON.parse(utf8.decode(bytes));
		if (permi !==null) {
			for (let index = 0; index < permi.length; index++) {
				const element = permi[index];
                if (permiso===element) {
                    return true;
                }
			}
            return false;
		}else{
            return false;
        }
}

export const devolverDepa = () => {
    const depa = '[{id:8,nombre:"BENI"},{id:1,nombre:"CHUQUISACA"},{id:3,nombre:"COCHABAMBA"},{id:2,nombre:"LA PAZ"},{id:4,nombre:"ORURO"},{id:5,nombre:"POTOSI"},{id:9,nombre:"PANDO"},{id:7,nombre:"SANTA CRUZ"},{id:6,nombre:"TARIJA"}]'
    let jsonStr = depa.replace(/(\w+:)|(\w+ :)/g, function(s) {
        return '"' + s.substring(0, s.length-1) + '":';
    });
    let depaUnico = JSON.parse(jsonStr);
    if (permiso('FP_VER_NACIONAL')) {
        return depaUnico;
    } else {
        return [depaUnico.find(c=>c.id===Number(localStorage.getItem('jwt_access_tema')))];
    }
}
