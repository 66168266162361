import React from 'react'

const PersonasPruebasPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/personas',
            component: React.lazy(()=>import('./PersonasPruebasPage'))
        }
    ]
}

export default PersonasPruebasPageConfig
