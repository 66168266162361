import Axios from 'axios';
import { permiso } from '../jwtService/helper';

export const registrarHoraContinuo = async (dep, muni, fechaIni, fechaFin,horaIni, horaFin, detalle) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        let municipi;
        if (muni===null) {
            municipi= null;
        } else {
            municipi=muni.id;
        }
        const cuerpo={
            departamento: dep.id,
            municipio   : municipi,
            fechaIni    : fechaIni,
            fechaFin    : fechaFin,
            detalle     : detalle,
            horaIni,
            horaFin,
        }
        const respuesta = await Axios.post(`${rutabase}/api/v1/horariocontinuo`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}


export const listarHoraContinuo = async (skip, take, fecha='', mes='00', departamento=0) => {

    const rutabase = process.env.REACT_APP_CONEXION;
    const permi = permiso('FP_VER_NACIONAL');
    let depa;
    if (!permi) {
        depa = Number(localStorage.getItem('jwt_access_tema'));
    }else{
        depa = departamento;
    }

    const cuerpo = {
        skip:skip,
        take:take,
        bsqFecha:fecha,
        bsqMes:mes,
        bsqDepartamento:depa,
    }

    try {
        const respuesta = await Axios.post(`${rutabase}/api/v1/horariocontinuo/listar`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            }
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });

        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: err,
        }
    }
}

export const eliminarHoraContinuo = async (id, estado) =>{

    const rutabase = process.env.REACT_APP_CONEXION;

    try {
        const respuesta = await Axios.delete(`${rutabase}/api/v1/horariocontinuo/${id}`,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                            data:{estado:estado}
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });

        const {error, message, response, status} = respuesta;
        return  {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }

}

export const actualizarHoraContinuo = async (id, dep, muni, fecha, detalle, horaIni, horaFin) =>{
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        let municipi;
        if (muni===null) {
            municipi= null;
        } else {
            municipi=muni.id;
        }
        const cuerpo={
            departamento: dep.id,
            municipio_id: municipi,
            fecha       : fecha,
            detalle     : detalle,
            horaInicial : horaIni,
            horaFinal   : horaFin,
        }
        const respuesta = await Axios.put(`${rutabase}/api/v1/horariocontinuo/${id}`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data;
                                    });
        const {error, message, response, status} = respuesta;
        return {
                mensaje: message,
                respuesta: response,
                errores: error,
                estado: status,
            };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}