import { authRoles } from 'app/auth'
import React from 'react'

const FeriadosPageConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: authRoles.staff,
    routes: [
        {
            path: '/feriados',
            component: React.lazy(()=>import('./FeriadosPage'))
        }
    ]
}

export default FeriadosPageConfig
