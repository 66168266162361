import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	{
		id: 'applications',
		title: 'Applications',
		translate: 'APPLICATIONS',
		type: 'group',
		children: [
			{
				id: 'analytics',
				title: 'Macro estadisticas',
				type: 'item',
				icon: 'assessment',
				url: '/analytics'
			}
		]
	},
	{
		id: 'personas',
		title: 'Personas',
		translate: 'PERSONAS',
		type: 'group',
		children: [
			{
				id: 'gestorPersonas',
				title: 'Gestor Personas',
				type: 'item',
				icon: 'assignment_ind',
				url: '/gestorPersonas'
			},
			/* {
				id: 'personas',
				title: 'Personas',
				type: 'item',
				icon: 'people',
				url: '/personas'
			}, */
		]
	},
	{
		id:'horarioContinuo',
		title: 'CATÁLOGO MARCACIÓN',
		type: 'group',
		children:[
			/* {
				id:'horariosAtencion',
				title: 'Horarios de Atencion',
				type : 'item',
				icon: 'location_city_icon',
				url: '/oficinas'
			},
			{
				id:'horarioscontinuos',
				title: 'Horarios Continuos',
				type : 'item',
				icon: 'access_time',
				url: '/horarioscontinuos'
			},
			{
				id:'feriados',
				title: 'Feriados',
				type : 'item',
				icon: 'weekend',
				url: '/feriados'
			}, */
			{
				id:'tiposHorario',
				title: 'Tipos de Horarios',
				type : 'item',
				icon: 'grid_on',
				url: '/tiposHorario'
			},
		]
	},
];

export default navigationConfig;
