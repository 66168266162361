import Axios from 'axios';

export const crearTipoHorario = async (data) => {
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.post(`${rutabase}/api/v1/tipohorarios`, data,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            },
                                        })
                                    .then((resp)=>{return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (error) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}

export const listarTipoHorarios = async (skip, take, tipoHorario, nombreTipoHorario) =>{
    const rutabase = process.env.REACT_APP_CONEXION;
    let cuerpo;
    if (tipoHorario===0 && nombreTipoHorario==='') {
        cuerpo={
            skip,
            take,
        }
    } else {
        if (tipoHorario!==0 && nombreTipoHorario!=='') {
            cuerpo={
                skip,
                take,
                bsqTipoHora:tipoHorario,
                bsqNombre:nombreTipoHorario,
            }
        } else {
            if (tipoHorario!==0 && nombreTipoHorario==='') {
                cuerpo={
                    skip,
                    take,
                    bsqTipoHora:tipoHorario,
                }
            } else {
                cuerpo={
                    skip,
                    take,
                    bsqNombre:nombreTipoHorario,
                }
            }
        }
    }

    try {
        const respuesta = await Axios.post(`${rutabase}/api/v1/tipohorarios/listar`, cuerpo,
                                        {
                                            headers:{
                                                'Content-Type': 'application/json',
                                                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                            }, /* params:{skip:skip, take:take} */
                                        })
                                    .then((resp)=>{ return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (err) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: err,
        }
    }
}

export const eliminarTipoHorario = async (id,estado) => {
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.delete(`${rutabase}/api/v1/tipohorarios/${id}`,
                                    {
                                        headers:{
                                            'Content-Type': 'application/json',
                                            'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
                                        },
                                        data:{estado:estado}
                                    })
                                    .then((resp)=>{return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (error) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}

export const actualizarTipoHorario = async (id, data) => {
    const rutabase = process.env.REACT_APP_CONEXION;
    try {
        const respuesta = await Axios.put(`${rutabase}/api/v1/tipohorarios/${id}`, data,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${localStorage.getItem('jwt_access_token')}`
            },
        })
                                    .then((resp)=>{return resp.data})
                                    .catch((error)=>{
                                        return error.response.data
                                    });
        const {error, message, response, status} = respuesta;
        return {
            mensaje: message,
            respuesta: response,
            errores: error,
            estado: status,
        };
    } catch (error) {
        return {
            mensaje: 'ERROR 500 en el servicio',
            estado : 500,
            respuesta: null,
            errores: true,
        }
    }
}